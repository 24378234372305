import { FC, useEffect, useState } from 'react';
import { ModuleSummary, ModuleType } from '../../models/Module';
import { Heading, HeadingSize } from '../../components/shared/text/Heading';
import ModuleCard from '../../components/module/ModuleCard';
import ModuleService from '../../services/ModuleService';
import { currentClientAtom } from '../../recoil/atoms/Clients';
import { useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
import ModuleUtils from '../../utils/ModuleUtils';
import TopNavPortal from '../../components/layout/top-menu/TopNavPortal';
import ClientTemplateModuleService from '../../services/ClientTemplateModuleService';
import PageLoader from '../../components/shared/page-loader/PageLoader';
import InfoIcon from '../../components/shared/icon/InfoIcon';

const ModuleCardList: FC = () => {
  const [modules, setModules] = useState<ModuleSummary[]>([]);
  const client = useRecoilValue(currentClientAtom);
  const { t } = useTranslation('module-list');
  const [isLoading, setIsloading] = useState(false);

  useEffect(() => {
    if (client?.id) {
      setIsloading(true);
      new ClientTemplateModuleService(client.id).getTemplates(false, true, [ModuleType.Default]).then((res) => {
        const moduleTemplates = res.data.map((moduleTemplate, index) => {
          return {
            id: moduleTemplate.id,
            name: ModuleUtils.getName(moduleTemplate),
            description: ModuleUtils.getDescription(moduleTemplate),
            iconCode: moduleTemplate.iconCode,
            moduleNumber: index + 1,
            isLocked: moduleTemplate.isLocked,
            isOpen: false,
          } as ModuleSummary;
        });
        ModuleService.getModules()
          .then((res) => {
            res.data.forEach((clientModule) => {
              const moduleTemplate = moduleTemplates.find((x) => x.id == clientModule.templateId);
              if (moduleTemplate) {
                moduleTemplate.id = clientModule.id;
                moduleTemplate.iconCode = clientModule.iconCode;
                moduleTemplate.progress = clientModule.progress;
                moduleTemplate.totalAssets = clientModule.totalAssets;
                moduleTemplate.approvedAssets = clientModule.approvedAssets;
                moduleTemplate.validatedAssets = clientModule.validatedAssets;
                moduleTemplate.inProgressAssets = clientModule.inProgressAssets;
                moduleTemplate.totalHighRiskAssets = clientModule.totalHighRiskAssets;
                moduleTemplate.openAssetProgress = clientModule.openAssetProgress;
                moduleTemplate.isOpen = !!clientModule && !moduleTemplate.isLocked;
                moduleTemplate.totalOpenAssets = clientModule.totalOpenAssets;
              }
            });
            setModules(moduleTemplates.sort((a, b) => a.sortOrder - b.sortOrder));
          })
          .finally(() => setIsloading(false));
      });
    }
  }, [client?.id]);

  return (
    <div className="bg-background-1 h-full min-h-full p-6">
      <TopNavPortal>
        <Heading size={HeadingSize.H2} actualSize={HeadingSize.H3}>
          {t('heading')}
        </Heading>
      </TopNavPortal>
      <PageLoader loading={isLoading}>
        <div className="flex flex-wrap gap-4">
          {modules.map((module) => {
            return <ModuleCard module={module} key={module.id} />;
          })}
        </div>
      </PageLoader>
      {!modules.length && !isLoading && (
        <div data-cy="modules-empty" className="flex h-full flex-col items-center justify-center ">
          <div className="text-center">
            <InfoIcon className="bg-primary-1 text-primary-1 my-2 h-16 w-16 rounded-full bg-opacity-10 p-4" />
            <Heading size={HeadingSize.H3} className="my-4">
              {t('empty.title')}
            </Heading>
            <div className="text-dpm-20">{t('empty.description')}</div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ModuleCardList;
