import { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { currentClientAtom } from '../../recoil/atoms/Clients';
import DateUtils from '../../utils/DateUtils';
import ProgressBar from '../shared/ProgressBar';
import { ModuleSummary } from '../../models/Module';
import { Heading, HeadingSize } from '../shared/text/Heading';
import Button from '../shared/form-control/Button';
import ModuleService from '../../services/ModuleService';
import PageLoader from '../shared/page-loader/PageLoader';
import { Roles } from '../../models/Role';
import { Trans, useTranslation } from 'react-i18next';
import LockIcon from '../shared/icon/LockIcon';
import IconRender from '../shared/icon/IconRender';
import Tooltip from '../shared/Tooltip';
import StringUtils from '../../utils/StringUtils';
import usePermissions from '../../hooks/permissions/usePermissions';
import { ModalContext } from '../../contexts/ModalContext';
import ConfirmationModal from '../shared/modal/variants/ConfirmationModal';

type ModuleCardProps = {
  module: ModuleSummary;
};

const ModuleCard: FC<ModuleCardProps> = (props) => {
  const {
    id,
    moduleNumber,
    name,
    description,
    iconCode,
    openTasks,
    totalOpenAssets,
    totalHighRiskAssets,
    nextTaskName,
    nextTaskDate,
    isLocked,
    openAssetProgress,
    isOpen,
  } = props.module;
  const navigate = useNavigate();
  const currentClient = useRecoilValue(currentClientAtom);
  const daysTillDue = DateUtils.getDaysBetween(nextTaskDate ? new Date(nextTaskDate) : new Date());
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation('module-list');
  const hasPermission = usePermissions();

  const onGetStarted = () => {
    if (currentClient) {
      setIsLoading(true);
      ModuleService.createModule(currentClient?.id, id)
        .then((res) => {
          setIsLoading(false);
          navigate(`/clients/${currentClient?.id}/modules/${res.data.id}`);
        })
        .catch(() => {
          setIsLoading(false);
        });
    }
  };

  const canStartModule = hasPermission(Roles.TeamLead) && !isLocked;
  const hideStats = !hasPermission(Roles.Management);

  return (
    <>
      <PageLoader loading={isLoading} />
      <div
        className={`border-gray-5 relative flex min-h-80 w-64 flex-col rounded-xl border-2 bg-white shadow-xl ${
          isOpen ? 'transform transition-transform duration-300 ease-in-out hover:scale-105' : ''
        }`}
      >
        {/* not started overlay */}
        {!isOpen && (
          <div className="parent absolute bottom-0 left-0 right-0 top-0 z-10 rounded-xl bg-white">
            {/* non-hover state */}
            <div className="absolute bottom-0 left-0 right-0 top-0 flex flex-col justify-center">
              {isLocked && <LockIcon className="absolute right-0 top-0 m-4 h-6 w-6" />}
              <div className="h-48 pt-6 text-center">
                <div className="bg-background-1 mx-auto w-fit rounded-full p-6">
                  <IconRender type={iconCode} gradient className="h-10 w-10" />
                </div>
                <div className="text-color-3 text-dpm-12 mt-4 font-medium">
                  <Trans t={t} i18nKey="card.number" values={{ number: moduleNumber }} />
                </div>
              </div>
              <div
                className="border-primary-2 flex h-32 items-center justify-center border-t-2 p-6 text-center"
                style={{ wordBreak: 'break-word', hyphens: 'auto' }}
              >
                <Heading size={HeadingSize.H5} className="font-medium ">
                  {StringUtils.truncate(name, 27)}
                </Heading>
              </div>
            </div>
            {/* hover state */}
            <div className="parent-hover:opacity-100 absolute bottom-0 left-0 right-0 top-0 flex flex-col rounded-xl bg-white opacity-0 transition-opacity duration-300 ease-in-out">
              {isLocked && <LockIcon className="absolute right-0 top-0 m-4 h-6 w-6" />}
              <div className="flex h-48 flex-col items-center justify-center pt-6">
                <div className="bg-background-1 rounded-full p-4">
                  <IconRender type={iconCode} gradient className="h-6 w-6" />
                </div>
                <div className="text-color-3 text-dpm-12 mt-4 font-medium">
                  <Trans t={t} i18nKey="card.number" values={{ number: moduleNumber }} />
                </div>
                <Tooltip text={name.length > 27 && name}>
                  {(tooltip) => (
                    <div {...tooltip} style={{ wordBreak: 'break-word', hyphens: 'auto' }}>
                      <Heading size={HeadingSize.H5} className="mt-2  text-center font-medium">
                        {StringUtils.truncate(name, 27)}
                      </Heading>
                    </div>
                  )}
                </Tooltip>
              </div>
              <div className="border-primary-2 flex min-h-32 flex-grow flex-col items-center justify-center border-t-2 py-6">
                <Button data-cy={`module-start-${moduleNumber}`} onClick={onGetStarted} className="mb-3" disabled={!canStartModule}>
                  {t('card.buttons.start')}
                </Button>
                <div data-cy={`module-info-${moduleNumber}`} className="text-color-2 cursor-pointer font-medium" onClick={() => setShowModal(true)}>
                  {t('card.buttons.info')}
                </div>
              </div>
            </div>
          </div>
        )}
        {/* started state */}
        <div className="flex h-full cursor-pointer flex-col" onClick={() => navigate(`/clients/${currentClient?.id}/modules/${id}`)}>
          <div className="border-primary-2 min-h-16 w-full border-b-2 p-4">
            <div className="text-dpm-12 text-color-3">
              <Trans t={t} i18nKey="card.number" values={{ number: moduleNumber }} />
            </div>
            <div className="flex items-center gap-2">
              <IconRender type={iconCode} gradient className="h-4 w-4" />
              <div className="text-dpm-14 truncate font-medium text-black">{name}</div>
            </div>
          </div>
          <div className="text-color-3 flex w-full flex-grow flex-col p-4 pb-1">
            {hideStats && (
              <div className="flex-grow py-10 text-center">
                <IconRender type={iconCode} gradient className="h-12 w-12" />
                <div className="text-color-3 text-dpm-12 mt-4 font-medium">
                  <Trans t={t} i18nKey="card.number" values={{ number: moduleNumber }} />
                </div>
              </div>
            )}
            {!hideStats && (
              <>
                {nextTaskName && (
                  <>
                    <div className="text-dpm-12 text-color-2 font-medium">
                      {daysTillDue === 0 ? t('card.next-task-today') : t('card.next-task', { days: daysTillDue })}
                    </div>
                    <div className="text-dpm-12 text-color-3">{nextTaskName}</div>
                  </>
                )}
                {!nextTaskName && (!openTasks || openTasks === 0) && (
                  <>
                    <div className="text-dpm-12 text-color-2 font-medium">{t('card.no-tasks')}</div>
                    <div className="text-dpm-12 text-color-3">&nbsp;{/* SPACER */}</div>
                  </>
                )}

                {!nextTaskDate && !!openTasks && openTasks >= 1 && (
                  <>
                    <div className="text-dpm-12 text-color-2 font-medium">{t('card.no-tasks-due')}</div>
                    <div className="text-dpm-12 text-color-3">&nbsp;{/* SPACER */}</div>
                  </>
                )}
                <div className="mt-auto flex flex-col gap-1">
                  <div className="border-gray-6 text-dpm-12 flex justify-between rounded-sm border-2 p-2 text-black">
                    <div>{t('card.high-risk-tasks')}</div>
                    <div>{totalHighRiskAssets || 0}</div>
                  </div>
                  <div className="border-gray-6 text-dpm-12 flex justify-between rounded-sm border-2 p-2 text-black">
                    <div>{t('card.open-assets')}</div>
                    <div>{totalOpenAssets}</div>
                  </div>
                </div>
              </>
            )}
          </div>
          {!hideStats && (
            <div className="border-gray-5 h-16 w-full border-t-2 p-4">
              <div className="text-dpm-12 text-color-3 flex justify-between">
                <div>{t('card.open-asset-progress')}</div>
                <div>{Math.round(openAssetProgress * 100)}%</div>
              </div>
              <ProgressBar progress={openAssetProgress || 0} disabled={!openAssetProgress} useProgressColours />
            </div>
          )}
        </div>
      </div>
      <ModalContext.Provider value={{ open: showModal }}>
        <ConfirmationModal
          title={t('card.info-modal.heading', { number: moduleNumber, name: name })}
          description={description}
          cancelText={t('card.buttons.close')}
          confirmText={t('card.buttons.start')}
          onCancel={() => setShowModal(false)}
          onConfirm={onGetStarted}
          confirmDisabled={!canStartModule}
        />
      </ModalContext.Provider>
    </>
  );
};

export default ModuleCard;
